<template>
  <div class="rongyu-container">
    <!-- <div class="head-warp">
      <div class="back" >
         <img class="back1" :style="{'left':left+'px'}" src='/static/back3.jpg'>
         <img class="back2" :style="{'left':left+'px'}" src='/static/back4.png'>
      </div>
      <div class="head-title">
         <p class="title"><img src="/static/chenwen.png"></p>
       </div>
    </div> -->
    <div class="content">
      <!-- <h3 class="title-background">公司荣誉</h3> -->
       <div >
         <!-- <div class="img-box" style="backgroundImage:url(static/bg5.png)">
         <img src="/static/honor.png">
         </div> -->
         <div class="detail">
           2018年中国（小谷围）“互联网+交通运输”创新创业大赛中，荣获“人工智能+交通”主题赛三等奖
         </div>
       </div>
    </div>
    <!-- <foot/> -->
  </div>
</template>
<script>
    // import foot from './footer'
  export default{
    components:{
      // foot,
    },
    data(){
      return{
clientWidth:document.body.clientWidth,
      }
    },
    mounted(){
      window.onresize = function windowResize () {
                     // 通过捕获系统的onresize事件触发我们需要执行的事件
                     this.clientWidth = document.body.clientWidth
                     // console.log(this.clientWidth)

                 }.bind(this)
    },
    computed:{
      left(){
        //if(this.clientWidth>1400){
        return (this.clientWidth-1920)/2
    //  }
      }
    },
    methods:{

    }
  }
</script>
<style lang="scss" scoped>
@import '../style/patent';
</style>
