<template>
  <div class="new-product-container">
    <div class="head-warp" >
       <div class="back" style="backgroundImage:url(static/01banner.jpg)"></div>
       <div class="head-title">
          <div class="title">
             <!-- <div>{{content.name}}</div> -->
             <div>以服务伙伴为中心,</div>
             <div style="margin-left:200px;">以场景落地为导向</div>
         </div>
       </div>
     </div>
     <div class="content" style="backgroundImage:url(static/bg4.jpg)" >
       <div class='tixi' ref='i1' :class="{'content-animation':ifView}">
          <h3>视频空间大数据平台</h3>
          <i class="i1" ref='i1' :class="{'i1-animation':ifView}"></i>
          <div class="flex-box" >
            <div v-for="(item,index) in ar.slice(0,4)">
              <img src="static/u.png">
             <div>{{item}}</div>
            </div>
          </div>
          <div class="flex-box" >
            <div v-for="(item,index) in ar.slice(4,8)">
              <img src="static/u.png">
             <div>{{item}}</div>
            </div>
          </div>
      </div>
      <div class="tixi" ref='i3' :class="{'content-animation':ifView3}">
         <h3>八大技术体系</h3>
         <i class="i1" :class="{'i1-animation':ifView3}"></i>
         <div class="flex-box">
           <div v-for="item in tixi.slice(0,4)">
              <img src="static/wubianxing.png">
               <div v-html="item"></div>
           </div>
         </div>
         <div class="flex-box">
           <div v-for="item in tixi.slice(4,8)">
              <img src="static/wubianxing.png">
               <div v-html="item"></div>
           </div>
         </div>
      </div>
      <div class="product" ref='i2'  :class="{'content-animation':ifView1}">
        <h3>产品</h3>
        <i class="i1" :class="{'i1-animation':ifView1}"></i>
        <!-- <swiper :options="swiperOption" class="horizontal-swiper" ref="mySwiper"  v-if="product.length>1"> -->
            <!-- <swiper-slide class="horizontal-swiper-item" v-for="(item,index) in product" :key="index"> -->
            <div class="horizontal-swiper" v-for="(item,index) in product">
              <div class="product-box" >
                 <div v-for="(itemm,indexx) in item" style="backgroundImage:url(static/probg.png)">
                    <div class="img-box" >
                     <img :src="index==0?'static/icon'+indexx+'.png':'static/icon'+(indexx+4)+'.png'">
                    </div>
                    <div class="name">{{itemm.name}}</div>
                  </div>
              </div>
            </div>
            <!-- </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper> -->
      </div>
      </div>
     <foot/>
  </div>
</template>
<script>
    import foot from '../footer'
    import headWarp from '../headWarp'
  //  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  export default{
    components:{
      foot,
      headWarp,
      //swiper,
    //  swiperSlide,
    },
    data(){
      return{
        tixi:['全面<br>实景建档','融合<br>实景轨迹','关系<br>实景图谱','实景数据<br>模型仪表盘',
              '实景化<br>预警体系','实景数据<br>标签体系','高融合<br>实景地图系统','融合的<br>实景检索'],
        ar:['更人性','更直观','更简单','更智能','更全面','更细腻','更开放','更规范'],
        ifView:true,
        ifView1:false,
        ifView3:false,
       content:'',
       clientHeight : document.documentElement.clientHeight || window.innerHeight,
        product:[],

      }
    },
    mounted(){
     //this.ifView=true
     this.product.push(this.$store.state.product)
     this.product.push(this.$store.state.product1)
    },
    computed:{
      scroll(){
        return this.$store.state.scrollTop
      },

    },
    methods:{

    },
    watch:{
      scroll(newValue){
          // console.log(this.$refs.i1.offsetTop<(this.clientHeight+newValue))
          if(this.$refs.i1.offsetTop<(this.clientHeight+newValue)&&this.$refs.i1.offsetTop>newValue){
            this.ifView=true
        } else{
        //  this.ifView=false
        }
        if(this.$refs.i2.offsetTop<(this.clientHeight+newValue)&&this.$refs.i2.offsetTop>newValue){
          this.ifView1=true
      } else{
        //this.ifView1=false
      }
      if(this.$refs.i3.offsetTop<(this.clientHeight+newValue)&&this.$refs.i3.offsetTop>newValue){
        this.ifView3=true
    } else{
    //  this.ifView3=false
    }
      }
    }
  }
</script>
<style lang="scss" scoped>
@import './newProduct';
</style>
