<template>
  <div class="case-container">
    <div class="head-warp" >
       <div class="back" style="backgroundImage:url(static/03banner.jpg)"></div>
       <div class="head-title">
          <div class="title">
            <div>专注用户体验，落地最优解决方案</div>
            <div>提升产品质量，专心做好每一个产品</div>
        </div>
       </div>
     </div>
     <div class="content" style1="backgroundImage:url(static/u17.jpg)">
              <div v-for="(item,index) in tab" @mouseenter="inter(index)" :style="{'backgroundImage':'url(static/pic'+index+'.png)'}">
                <div class="bg"></div>
                <div class="text">
                   <p class="number">{{item.number}}+</p>
                   <p class="name">{{item.name}}</p>
                   <p class="ali">{{item.ali}}</p>
                </div>
              </div>
            </div>
      <foot/>
  </div>
</template>
<script>
    import foot from '../footer'
  export default{
    components:{
      foot,
    },
    data(){
      return{
      //  ifView:true,
        ifView1:true,
          tab:[{
            name:'智慧公安',
            number:60,
            ali:'Intelligent Security'
          },{
            name:'智慧交通',
            number:20,
            ali:'Intelligent Transportation',
          },{
            name:'智慧旅游',
            number:10,
             ali:'Intelligent Tourism'}
          ,{
            name:'智慧教育',
            number:20,
            ali:'Wisdom educa'
          },{
            name:'智慧生产',
            number:10,
            ali:'Intelligent production'}
          ,{
            name:'智慧通信',
            number:5,
            ali:'Intelligent Communication'
            }],

      }
    },
    mounted(){

    },
    computed:{
      scroll(){
        return this.$store.state.scrollTop
      },

    },
    methods:{
      inter(index){
        var number=this.tab[index].number
        this.tab[index].number=0
        var interval=setInterval(()=>{
          if(this.tab[index].number>=number){
          clearInterval(interval)
          console.log(this.tab[index].number)
          return
        }
          this.tab[index].number++
        },20)
      }
    },
    watch:{
      scroll(newValue){
          // console.log(this.$refs.i1.offsetTop<(this.clientHeight+newValue))
        //   if(this.$refs.i1.offsetTop<(this.clientHeight+newValue)&&this.$refs.i1.offsetTop>newValue){
        //     this.ifView=true
        // } else{
        //   this.ifView=false
        // }
      //   if(this.$refs.i2.offsetTop<(this.clientHeight+newValue)&&this.$refs.i2.offsetTop>newValue){
      //     this.ifView1=true
      // } else{
      //   this.ifView1=false
      // }
      }
    }
  }
</script>
<style lang="scss" scoped>
@import './newCase';
</style>
