<template>
  <div class="container">
    <div class="head-warp" >
       <div class="back" :style="img"></div>
       <div class="head-title">
          <div class="title">
             <!-- <div>{{content.name}}</div> -->
             <div>视频即应用</div>
             <div >视频即服务</div>
         </div>
       </div>
     </div>
     <div class="content" style="backgroundImage:url(static/backblue.jpg)">
       <swiper :options="swiperOption"  class="vertical-swiper" ref="mySwiper" id="mySwiper">
          <swiper-slide class='vertical-swiper-item' >
            <div class="jigou">
              <h3>核心研发机构</h3>
              <i class="i1" :class="{iWidth:index==0}"></i>
              <div class="departmen" :class="{'content-animation':index==0}">
                 <div class="number">
                   <span class="em">2</span><span class="em2">个研发部门</span>
                   <div>R & D department</div>
                 </div>
                 <div class="yan1" >
                   <img src="static/wubianxing.png">
                   <span>全域地图核心<br/>技术研发</span>
                   <div>研发一部</div>
                 </div>
                 <div class="yan1" >
                   <img src="static/wubianxing.png">
                   <span>增强现实视频AR<br/>核心技术研发</span>
                   <div >研发二部</div>
                 </div>
               </div>
               <div class="departmen" :class="{'content-animation':index==0}">
                    <div class="number">
                      <span class="em">1</span><span class="em2">个核心技术研究院</span>
                      <div>Research Institute of Core Technology</div>
                     </div>
                     <div class="yan2">
                     <div class="yan1" >
                       <img src="static/wubianxing.png">
                       <span>人工智能、大数<br>据台、物联网等<br>核心技术研发</span>
                       <div>核心技术院</div>
                     </div>
                     </div>
                 </div>
           </div>
          </swiper-slide>
            <swiper-slide class='vertical-swiper-item' >
              <div class="ai">
                  <h3>场景AI</h3>
                  <i class="i1" :class="{iWidth:index==1}"></i>
                 <div class="ai-box" :class="{'content-animation':index==1}">
                 <div v-for="item in AI.slice(0,3)" class="item">
                   <!-- <svg class="icon " aria-hidden="true">
                     <use :xlink:href="'#'+item.icon"></use>
                   </svg> -->
                   <i class="iconfont icon" :class="item.icon"></i>
                   <div class='title'>{{item.title}}</div>
                  </div>
                </div>
                <div class="ai-box" :class="{'content-animation':index==1}">
                <div v-for="item in AI.slice(3,6)" class="item">
                  <!-- <svg class="icon" aria-hidden="true">
                    <use :xlink:href="'#'+item.icon"></use>
                  </svg> -->
                  <i class="iconfont icon" :class="item.icon"></i>
                  <div class='title'>{{item.title}}</div>
                 </div>
               </div>
              </div>
              </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>

        <!-- <div >
          <h3>场景AI</h3>
          <i class="i1"></i>
          <div class="ai-box">
           <div class="box" v-for="item in AI">
           <div>{{item.title}}</div>
           <img :src='item.img'>
           <p>{{item.content}}</p>
          </div>
          </div>
        </div> -->


     </div>
    <foot/>
  </div>
</template>
<script>
//  import headWarp from './headWarp'
  import foot from '../footer'
 //import { swiper, swiperSlide } from 'vue-awesome-swiper'
  export default{
    components:{
      foot,
      //swiper,
      //swiperSlide,
    //  headWarp
    },
    data(){
      return{
        index:0,
        clientHeight:document.body.clientHeight,
         content:{name:'场景AI',
            img:'static/02banner-AI.jpg'},
         AI:[{
           title:"人脸识别",
            icon:'icon-renlianshibie',
           img:"static/renlianshibie.png",
           content:'基于深度学习的人脸识别技术，广泛应用于人脸考勤、人脸点名、人员识别、人员躲藏、人员对比等场景，满足安防、教育、旅游等多个行业智能化应用需求。'
         },{
           title:'车辆识别',
            icon:'icon-cheliangshibie',
           img:'static/cheliangshibie.png',
           content:'基于智能视频分析和视频结构化技术，实现对车辆的特征分析，包括颜色、车型、车标、车牌号码等。主要用户车辆比对、危险车辆监控、车流分析、车牌识别、车辆跟踪等应用场景。'
         },
         // {
         //   title:"球机自动跟踪",
         //   img:"static/qiuji.gif",
         //   content:"基于智能视频分析中的目标检测、目标定位技术，对监控摄像球可视区域中的特定目标进行自动跟踪、变焦等操作，使目标对象始终居中于可视场景，减少工作人员在实战指挥过程中的重复动作，加快工作效率。"
         // },
         {
           title:'跨境追踪',
             icon:'icon-zhuizong',
           img:'static/kuajing.gif',
           content:'基于智能视频分析及行人重识别（ReID）技术，对视频监控中的重点监控对象实现联动周边视频自动追踪，支持多源、跨域、跨平台的视频监控接入，主要用于公安对嫌疑人的追踪、安保对可疑人员的跟踪、其他行业对重点监控人员的跟踪等。'
         },{
           title:'姿态识别',
            icon:'icon-rentifenxix',
           img:"static/zitai.gif",
           content:'基于深度学习的级联卷积神经网络预测人体关节点的技术，可以描述人体姿态、预测人体行为，该技术作为公司研究重点方向，主要用于监控人的动作分类、异常行为检测等，为公共安全提供更有力的技术保障。'
         },{
           title:'安全帽检测',
           icon:'icon-anquanmao',
           img:'static/anquanmao.gif',
           content:'基于智能视频分析和深度学习技术，通过实时视频对工作人员的安全帽的佩戴进行实时识别和检测，对未佩戴安全帽的危险行为可实时监测和预警。'
         },{
           title:"反光衣检测",
           icon:'icon-jingfu',
           img:'static/fanguangyi1.png',
           content:"基于智能视频分析和深度学习技术，通过实时视频对高速公路或其他道路作业现场工作人员的反光衣的穿戴进行实时识别和检测，对未按要求穿戴后者非工作人员入场的可实时监测和预警。"
         }],
         swiperOption: {
                 direction:'vertical',
                 slidesPerView: 1,
                 spaceBetween: 0,
                 autoplay : false,
                 mousewheel: true,
                 // effect : 'fade',//切换模式
                 speed:500,//速度
                 pagination: {
                 el: '.swiper-pagination',
                 clickable: true,
                 renderBullet: function (index, className) {
                 return '<span  class="' + className + ' bullet"></span>';
         },
       },
       on: {
           slideChangeTransitionEnd: function(){
             this.index=this.activeIndex
           }.bind(this),
         },
               },
      }

    },
    mounted(){
this.$refs.mySwiper.swiper.mousewheel.disable();
    },
    computed:{
      img(){
        return{
          backgroundImage: `url(${this.content.img})`
        }
      },
      activeIndex(){
        return this.$refs.mySwiper.swiper.activeIndex
      },
      scroll(){
        return this.$store.state.scrollTop
      },
    },
    methods:{

    },
    watch:{
      scroll(val){
        let height=document.getElementById('mySwiper').offsetHeight
        console.log(val+this.clientHeight>=height+488)
        if(val+this.clientHeight>=height+488){

          this.$refs.mySwiper.swiper.mousewheel.enable();
        }
      },

    }
  }
</script>
<style lang="scss" scoped>
@import './AI';
</style>
