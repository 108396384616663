<template>
  <div id="app" :fix="fix">
    <heade/>
    <!-- <keep-alive > -->
    <router-view/>
    <!-- </keep-alive> -->
  </div>
</template>

<script>
  import heade from './components/hader'
export default {
  components:{
    heade
  },
  name: 'App',
  mounted(){
    window.onresize = function windowResize () {
                   // 通过捕获系统的onresize事件触发我们需要执行的事件
                   this.$store.state.clientWidth = document.body.clientWidth
                   // console.log(this.clientWidth)

               }.bind(this)
    window.addEventListener('scroll', this.handleScroll)
  },
  computed:{
    fix(){
    this.$store.state.fix = this.$route.path=='/'||this.$route.path=='/case'||this.$route.path=='/zizhi'||this.$route.path=='/join'||this.$route.path=='/AI'?true:false
    return 0
    }
  },
  methods:{
   handleScroll(){   
       this.$store.state.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
   }
  }
}
</script>

<style lang='scss'>
  @import './style/app';
  @import './style/newapp';
</style>
