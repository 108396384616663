<template>
  <div class="index-container">
    <swiper :options="swiperOption" class="vertical-swiper" ref="mySwiper">
         <swiper-slide >
           <swiper :options="swiperOption1" class="horizontal-swiper" >
                <swiper-slide class="horizontal-swiper-item" v-for="item in homepage"  :key="item">
                      <div class="bg"  :style1="{backgroundImage:'url(' + item + ')'}" >
                          <img :data-src='item' style="width:100%;height:100%" class="swiper-lazy">
                          <div class="swiper-lazy-preloader"></div>
                      </div>
                </swiper-slide>
                <div class="swiper-pagination1" slot="pagination"></div>
              </swiper>
          </swiper-slide>
          <swiper-slide class='vertical-swiper-item'  v-for="(item,indexx) in content" :key="indexx">
               <div class="bg" :class="{animation:index==indexx+1}" :style1="{backgroundImage:'url(' + item.img + ')'}">
                 <img :data-src='item.img' style="width:100%;height:100%" class="swiper-lazy">
                 <div class="swiper-lazy-preloader"></div>
               </div>
                <sectionIco :index="index" :swiperIndex='indexx+1' :content="item.detail"/>
         </swiper-slide>
          <swiper-slide class='vertical-swiper-item'>
            <div class="foo">
              <div :class="{animation:index==5}" class="bg" style1="backgroundImage:url(static/concat.jpg)">
                <img data-src='static/concat.jpg' style="width:100%;height:100%" class="swiper-lazy">
                <div class="swiper-lazy-preloader"></div>
              </div>
              <foot class="foot"/>
            </div>
          </swiper-slide>
         <div class="swiper-pagination" slot="pagination"></div>
       </swiper>
  </div>
</template>
<script>

  import sectionIco from './section/section'
  import foot from './footer'
  export default{
    components:{
    //  swiper,
    //  swiperSlide,
      sectionIco,
      foot,
    },
    data(){
      return{
        index:0,
        clientHeight:document.body.clientHeight+'px',
        content:[{
          img:'static/homepage3.png',
          detail:{
          enTitle:'Super fusion',
          cnTitle:'超融合 ',
          cnContent:`通过人工智能、大数据、移动通信、物联网等多种技术，打造当前城市发展实景化、扁平化、立体化的超融合指挥防控体系`,
          enContent:`Through artificial intelligence, big data, mobile communication, Internet of Things and other technologies, we can build a realistic, flat and three-dimensional super-integrated command and control system for urban development.`,
        }
      },{
        img:'static/homepage4.jpg',
        detail:{
          enTitle:'VIDEO AI',
          cnTitle:'场景 AI',
          cnContent:`通过AI赋能的视频场景化应用，集成多种不同的人工智能分析工具，变不可能为可能。`,
          enContent:`Through AI enabled video scenario application, it is possible to integrate various AI analysis tools.`,
        }
      },{
        img:'static/homepage5.jpg',
        detail:{
          enTitle:'Visual path cloud',
          cnTitle:'视途云',
          cnContent:`同一双眼睛，不一样的感受<br>
                       你与未来的距离只差视途云`,
          enContent:`the same eyes, the different feelings,
                    the distance between you and the future is only the difference between Visual path cloud`,
        }
      },{
        img:'static/homepage6.jpg',
        detail:{
          enTitle:'AR PLUS',
          cnTitle:'',
          cnContent:`数字世界与现实世界的连接器<br>AR PLUS 让增强现实不止于看`,
          enContent:`Connector between Digital World and Real World`,
        }
      }
      ],
        swiperOption: {
          lazy: {
         loadPrevNext: true,
          },
                //direction: 'vertical',
                //loop:true,
                //init:false,
                //autoHeight: true,
                direction:'vertical',
                slidesPerView: 1,
                spaceBetween: 0,
                autoplay : false,
                mousewheel: true,
                // effect : 'fade',//切换模式
                speed:500,//速度
                pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                  console.log(className)
                return '<span  class="' + className + ' bullet"></span>';
        },
      },
      on: {
          slideChangeTransitionEnd: function(){
            this.index=this.activeIndex
          }.bind(this),
        },
              },
      swiperOption1: {
        lazy: {
   loadPrevNext: true,
 },
              //direction: 'vertical',
                loop:true,
                direction:'horizontal',
                slidesPerView: 1,
                spaceBetween: 0,
                //autoplay : true,
                //mousewheel: true,
                // effect : 'fade',//切换模式
                speed:1000,//速度
                pagination: {
                el: '.swiper-pagination1',
                clickable: true,
                renderBullet: function (index, className) {
                return '<span  class="' + className + ' bullet"></span>';
        },
                    }
              },

      homepage:['static/homepage2.png',
                'static/homepage1.png'],
      }
    },
    created(){

    },
    mounted(){

    },
    computed:{
      mySwiper() {
        return this.$refs.mySwiper.swiper
      },
      activeIndex(){
        return this.$refs.mySwiper.swiper.activeIndex
      }
    },
    methods:{

    }
  }
</script>
<style lang="scss" scoped>
@import '../style/index';
</style>
