<template>
  <div class="section-con">
     <h3 >{{content.enTitle}}</h3>
     <i class="i1" :class="{iWidth:index==swiperIndex}"></i>
     <h4>{{content.cnTitle}}</h4>
     <div class="content" v-html="content.cnContent":class="{'content-animation':index==swiperIndex}">
     </div>
     <i class="i2" :class="{'i2-width':index==swiperIndex}"></i>
     <div class="en-content" v-html="content.enContent" :class="{'content-animation':index==swiperIndex}">
     </div>
     <div class="img-box">
       <!-- <img src="static/arrow3.png"> -->
     </div>
  </div>
</template>
<script>
  export default{
    props:['index','content','swiperIndex'],
    data(){
      return{

      }
    },
    mounted(){

    },
    methods:{

    }
  }
</script>
<style lang="scss" scoped>
@import './section';
</style>
