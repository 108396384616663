// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import Vuex from 'vuex'
import router from './router'
//import axios from 'axios'
//import VueAxios from 'vue-axios'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper, /* { default global options } */ )
//import {  Dropdown,DropdownMenu, DropdownItem, Carousel, CarouselItem, } from 'element-ui';
//import 'element-ui/lib/theme-chalk/index.css';
//Vue.use(VueAxios, axios)
Vue.use(Vuex);
Vue.config.productionTip = false
// Vue.use(Dropdown);
// Vue.use(DropdownMenu);
// Vue.use(DropdownItem);
// Vue.use( Vuex );
// Vue.use(Carousel);
// Vue.use(CarouselItem);
// /* eslint-disable no-new */
const store = new Vuex.Store({
  state: {
    scrollTop: 0,
    fix: true,
    clientWidth: document.body.clientWidth,
    product: [{
      router: '/cvos',
      img: 'static/dashuju.png',
      name: '视频空间大数据平台',
      content: '视云融聚视频空间大数据平台是将实景指挥系统中和行业业务相关的功能进行剥离，提供标准的视频管理、视频实景地图管理、数据符号化管理，具有良好的扩展性',
    }, {
      //孔雀平台
      tedian: [{
        title: '兼容性及大数据思维',
        img: 'static/kongque1.png',
        content: '平台支持百度、高德开放地图平台，按行业特性叠加主题、场景数据。并将这些数据的各个属性值以多维数据的形式表示，从不同维度观察数据，从而对数据进行更深入的观察和分析'
      }, {
        title: 'AR实景管理',
        img: 'static/kongque2.png',
        content: '通过几何的技术、面向像素技术、基于图标的技术、基于层次的技术、基于图像的技术进行数据加视频处理。对人员信息、车辆信息按需进行监控。'
      }, {
        title: '增强视觉感知',
        img: 'static/kongque3.png',
        content: '对场所内的特定位置增加视觉感知，实现边界、区域安全防范。报警信息以声光等不同形式进行实时展现。'
      }, {
        title: '智能视频巡更',
        img: 'static/kongque4.png',
        content: '导入安保人员的排班表，将传统的巡更升级到智能视频巡更。同时对于人员非法入侵、滞留、徘徊等现象通过结构化技术实现实时检测。'
      }],
      router: '/kongque',
      img: 'static/kongque.png',
      name: '孔雀平台',
      tab: ['产品概述', '产品特点'],
      content: '超融合实景指挥系统民用版（孔雀）是视云融聚探索在教育、园区、景区、社区、高速服务区、工厂等非公安行业应用的一款低成本的实景综合安防管理系统。',
    }, {
      //月光宝盒
      tedian: [{
        title: '软硬一体化的视频回溯引擎',
        img: 'static/yueguang1.png',
        content: '可以根据事件信息、时间、空间等进行场景关联，提供定制化的视频回溯只能服务'
      }, {
        title: '多维时空泛在信息关联',
        img: 'static/yueguang2.png',
        content: '以“场景”为根本，动态关联事物或事件的多时态、多主题、多层次、多粒度的数据，提供个性化的“案件”回溯的智能服务。'
      }],
      router: '/kongque',
      img: 'static/yueguang.png',
      name: '月光宝盒',
      tab: ['产品概述', '产品特点'],
      content: '打造基于历史时空场景的全息回溯服务平台，改变传统的录像回放分散割裂的线索查找方式，案件中复杂的“人、地、事、物、组织”等要素关系能在全面、多源、直观的场景中进行交互和还原。',
    }, {
      //移动监控客户端
      tedian: [{
        title: '',
        img: 'static/yidong.png',
        content: ''
      }],
      router: '/kongque',
      img: 'static/yidong.png',
      name: '移动监控客户端',
      tab: ['产品概述', '产品特点'],
      content: '移动视频监控是集视频查看，监控，指挥，信息采集于一体的综合服务平台，具有覆盖面广、接入方便、效率高效、业务多样、监控方便、及时联动等的特点，是当前移动互联网时代不可缺失的移动监控工具',
    }],
    product1: [{
      router: '/kongque',
      img: 'static/quanyu.png',
      name: '全域地图服务平台',
      content: '该平台基于百度、高德、天地图等地图数据，构建全域地图共享服务平台，为用户及其他平台提供分布式可定制的地理信息服务。',
    }, {
      //视频结构化
      tedian: [{
        title: '',
        img: 'static/jiegou.png',
        content: '利用已经部署好的监控资源+AI算法，将非结构化的视频流变成可以检索的结构化数据。包括人员、机动车、非机动车等。识别出来的数据自动存储。'
      }],
      router: '/kongque',
      img: 'static/jiegou.png',
      name: '视频结构化',
      tab: ['产品概述', '产品特点'],
      content: '视频结构化平台是面向各行业视频图片内容解析的基础平台，支持多种视频图片多源接入、内容解析、人车特征识别、行为及事件分析等视频智能化应用。',
    }, {
      tedian: [{
        title: '',
        img: 'static/changjing1.png',
        content: '基于地图的场景化管理，在地图中查看场景的分布情况，点击标注可以快捷进入场景'
      }, {
        title: '',
        img: 'static/changjing2.png',
        content: '可以为每个场景中添加需要多个区域，实现全景图像的区域化管理'
      }, {
        title: "",
        img: 'static/changjing3.png',
        content: '在每个区域中，为摆放的物品添加标签，记录每个物品的详细信息，方便以后查阅'
      }],
      router: '/kongque',
      img: 'static/changjing.png',
      name: '场景小管家',
      tab: ['产品概述', '产品功能'],
      content: '景小管家主要应用于没有监控前端（含枪机、球机）覆盖的小区域或小场景，可以在一个或多个独立房间，通过房间实景照片，对房间内部的家具、设备、物品等进行标签标注，方便用户后续的维护和管理。',
    }, {
      tedian: [{
        title: '',
        img: "static/zaina1.png",
        content: '随时了解相关人员的实时动态，根据人员或时间查看运动轨迹，统计考情情况等'
      }, {
        title: '',
        img: "static/zaina2.png",
        content: '通过人脸识别技术，识别人员的详细信息，并可通过列表或地图的方式查看人员的详细轨迹'
      }, {
        title: '',
        img: "static/zaina3.png",
        content: '支持视频的实时查看，历史视频的回放查询，视频轮播等功能。'
      }],
      router: '/kongque',
      img: 'static/zaina.png',
      name: '在哪',
      tab: ['产品概述', '产品特点'],
      content: '在哪儿应用产品主要依托人工智能的人脸识别技术，通过前端摄像机的监控可以实时获取被监控人员的具体位置，并最终生成该人员的运动轨迹，该产品主要应用于安保的智能巡更，巡检人员的日常巡检和考勤等场景。',
    }],
  },
})
new Vue({
  router,
  store,
  render: function(h) {
    return h(App);
  },
}).$mount("#app");