import Vue from 'vue'
import Router from 'vue-router'
//import main from '@/components/main'
//import swiper from '@/components/swiper'
//import cvos from '@/components/cvos'
//import about from '@/components/about/about'
import join from '@/components/join/join'
// import contact from '@/components/contact'
//import coonperate from '@/components/coonperate'
import zizhi from '@/components/zizhi'
//import patent from '@/components/patent'
//import headWarp from '@/components/headWarp'
//import kongque from '@/components/kongque'
import AI from '@/components/AI/AI'
import casee from '@/components/case'
import newCase from '@/components/newCase/newCase'
import index from '@/components/index'
import newProduct from '@/components/newProduct/newProduct'
Vue.use(Router)

export default new Router({
  routes: [
    // {
    //   path: '/',
    //   name: 'main',
    //   component: main
    // },
    // {
    //   path: '/',
    //   name: 'swiper',
    //   component: swiper
    // },
    {
      path: '/',
      name: 'index',
      component: index,
    },
    // {
    //   path: '/cvos/:name',
    //   name: 'cvos',
    //   component: cvos,
    // },
    // {
    //   path: '/about',
    //   name: 'about',
    //   component: about
    // },
    {
      path: '/join',
      name: 'join',
      component: join
     },
     //{
    //   path: '/contact',
    //   name: 'contact',
    //   component: contact
    // },
    // {
    //   path: '/coonperate',
    //   name: 'coonperate',
    //   component: coonperate
    // },
    {
      path: '/zizhi',
      name: 'zizhi',
      component:zizhi
    },
    // {
    //   path: '/patent',
    //   name: 'patent',
    //   component:patent
    // },
    // {
    //   path: '/headWarp',
    //   name: 'headWarp',
    //   component:headWarp
    // },
    // {
    //   path: '/kongque/:name',
    //   name: 'kongque',
    //   component:kongque,
    //
    // },
    {
      path: '/AI',
      name: 'AI',
      component:AI,
    },{
      path: '/case',
      name: 'case',
      component:casee,
    },{
      path: '/newProduct',
      name: 'newProduct',
      component:newProduct,
    },{
      path: '/newCase',
      name: 'newCase',
      component:newCase,
    }
  ],
  scrollBehavior (to, from, savedPosition) {
  return { x: 0, y: 0 }
}
})
