<template>
  <footer class="footer">
     <div class="wrap">
        <div class="footer-container">
          <div class="imga">
                <a href="/" class="logo"><img src="/static/logo5.png" alt="视云融聚"></a>
          </div>
          <div class="detail">
            <p class="title">联系我们</p>
             <p >总部地址：广州市黄埔区南翔二路1号旗锐科技园A座二层</p>
             <p >业务热线：020-82003023</p>
             <p >合作邮箱：info@cloudvos.com</p>
             <p >传真：020-82557895</p>
          </div>
          <div class="banshichu">
             <p class="title">办事处热线</p>
             <p>北京办事处：18611633012</p>
             <p>西安办事处：029-88604186</p>
             <p>湖南办事处：0731-85517112</p>
             <p>杭州办事处：19805815460</p>
             <p>天津办事处：18918001990</p>
          </div>
          <div class="guanzhu">
            <p class="title index-footer-title">关注我们</p>
            <img src="/static/gongzhonghao.png">
              <p class="title footer-title">关注我们</p>
          </div>

        </div>
       </div>
       <div class="copyright"><p><span>Copyright ©2019 cloudVos All Rights Reserved</span><span class="f"@click="beian">粤ICP备18036966号-1</span><img src="/static/beian.png" class="icon-beian"><span class="f" >粤公网安备 44011202000515号</span></p></div>
  </footer>
</template>
<script>
  export default{
    data(){
      return{

      }
    },
    mounted(){

    },
    methods:{
      beian(){
        window.open('http://www.miitbeian.gov.cn')
      }
    }
  }
</script>
<style lang="scss" scoped>
  .footer{
      //margin-top: 120px;
     padding-top: 94px;
    padding-bottom: 16px;
    background-color: #262b38;
    .footer-container{
        width: 1000px;
       padding-bottom: 20px;
        margin: 0 auto;
        text-align: left;
          color: #fff;
          display: flex;
          justify-content: space-between;

        .imga{
        a{

           img{
             width: 150px;

           }

        }
      }
      .detail,.banshichu{
        p{
          opacity: .4;
          margin-bottom: 10px;
          letter-spacing: 0;
          font-size:15px;
        }
      }
      .guanzhu{
        top:-128px;
      position: relative;
      text-align: center;
        img{
          width: 98px;
      height: 98px;
      margin-bottom: 30px;
        z-index:999;
        position:relative;
        }
      }
      .index-footer-title{
        display:none;
      }
      .title{
        opacity: 1!important;
  font-size: 18px!important;
  line-height: 1;
  letter-spacing: 1.3px;
  color: #fff;
  margin-bottom: 17px!important;
      }
    }
    .copyright{
      span{
        display: inline;
        opacity: .4;
        color: #fff;
        letter-spacing: 0;
      }
     .icon-beian{
         width: 14px;
         border-style: none;
         margin: 0 5px;
     }
    }
  }
  .f{
    cursor:pointer;
  }
</style>
