<template>
  <div class="container">
    <div class="head-warp" >
       <div class="back" :style="img"></div>
       <div class="head-title">
          <p class="title">{{content.name}}</p>
          <p class="detail">
             {{content.content}}
          </p>
       </div>
     </div>
     <div class="head-tab"  :class="searchBarFixed?'fix':'relative'">
        <div class="head-tab-warp" >
           <span v-for="(item,index) in content.tab" @click="turn(index)">{{item}}</span>
           <!-- <span  @click="turn(1)">{{index}}</span> -->
           <!-- <span  @click="turn(3)">功能模块</span> -->
        </div>
        <div class="head-tab-bar" :barLeft="barLeft" :style="{left:barLeft+'px'}" ></div>
     </div>
     <div class="back-tab" :style="searchBarFixed?'display:block':'display:none'"></div>
  </div>
</template>
<script>
  export default{
    props:['searchBarFixed','barLeft','content','warpImg'],
    data(){
      return{
         //img:'url(/static/back2.jpg)'
      }
    },
    mounted(){
         //console.log(this.searchBarFixed)
    },
    computed:{
        img(){
          return{
            backgroundImage: `url(${this.warpImg})`
          }
        }
    },
    methods:{
       turn(index){
         this.$emit('turn',index)
       }
    }
  }
</script>
<style lang="scss" scoped>
  .head-warp{

    .back{
      //background-image: url(/static/back2.jpg);
      animation: fadeIn .5s ease-in .1s 1 normal forwards;;
      opacity: 0;
    }
    .head-title{
      animation:slideIn .8s cubic-bezier(.4,0,.2,1) 0s 1;

    }
  }
    .relative{
        position: relative;
    }
    .fix{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
    .back-tab{
      width: 100%;
   height: 72px;
   display: none;
    }

  .head-tab{

    width: 100%;
    height: 72px;
    background-color: #262b38;
    .head-tab-warp{
      width: 1000px;
  height: 74px;
  margin: 0 auto;
  display: flex;
  justify-content:space-between;
      span{
      display: inline-block;
      width: 220px;
      padding: 28px 0;
      box-sizing: border-box;
      line-height: 1;
      font-size: 18px;
      color: #fff;
      user-select: none;
      float: left;
      cursor: pointer;
      &:hover{
        color: #42fff7;
      }
    }
    & span+span{
      margin-left: 48px;
    }
    }
    .head-tab-bar{
      width: 220px;
      position: relative;
      height: 4px;
      background-color: #42fff7;
      top: -6px;
      transition: .3s 0s left ease;
    }
  }

</style>
