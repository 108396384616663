<template>
  <div class="container">
    <!-- <div class="right-tab">
       <div class="tab"  v-for="item in tab" @click="turn(item)"><span>{{item.name}}</span></div>
      </div> -->
  <div class="head-warp">
    <div class="back" >
       <img class="back1" :style="{'left':left+'px'}" src='/static/04banner.jpg'>
       <!-- <img class="back2" :style="{'left':left+'px'}" src='/static/brath2.png'> -->
       <a class="pdflink" href="/static/视云融聚公司彩页V1.1-2018.0325.pdf" target="_blank">视云融聚公司彩页</a>
    </div>
    <div class="head-title">
       <!-- <p class="title"><img src="/static/chenwen.png"></p> -->
       <div class="title">
         <p>创新、融合、责任</p>
         <p>品牌、开拓、生态</p>
         </div>
     </div>
  </div>
  <swiper :options="swiperOption"  class="vertical-swiper" ref="mySwiper" id="mySwiper1">
    <swiper-slide class='vertical-swiper-item' >
      <!-- <div class="bg" style="backgroundImage:url(static/jianjie.jpg)"></div> -->

      <about class="about" :index="index" swiperIndex='0' id="about" />
   </swiper-slide>
  <swiper-slide class='vertical-swiper-item' >
    <div class="zhuanli">
      <div>
         <div>
           <span>软件著作权:</span>
           <swiper :options="swiperOption1"  class="vertical-swiper-swiper" ref="mySwiper1" >
              <swiper-slide class='vertical-swiper-swiper-item' v-for="item in zhuozuo" :key="item">
                  {{item}}
              </swiper-slide>
           </swiper>
         </div>
      </div>
      <div>
        <div>
          <span>技术专利:</span>
          <swiper :options="swiperOption1"  class="vertical-swiper-swiper" ref="mySwiper2" >
             <swiper-slide class='vertical-swiper-swiper-item' v-for="item in zhuanli" :key="item">
                 {{item}}
             </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
     </swiper-slide>
   <!-- <swiper-slide class='vertical-swiper-item' >
     <div class="box">
       <div class="title" id="zizhi">知识产权</div>
       <i class="i1" :class="{iWidth:index==1}"></i>
       <swiper :options="swiperOption1" class="horizontal-swiper" ref="mySwiper1">
           <swiper-slide class="horizontal-swiper-item" v-for="(item,index) in img1" :key="index">
              <div class="img-container" >
                <div v-for="itemm in item">
                <img   :src="itemm">
                </div>
             </div>
           </swiper-slide>
           <div class="swiper-pagination1" slot="pagination"></div>
         </swiper>
      </div>
   </swiper-slide> -->
   <!--  -->
   <!-- <swiper-slide class='vertical-swiper-item' >
     <div class="box">
       <div class="title"  id="zhuanli">公司专利</div>
       <i class="i1" :class="{iWidth:index==2}"></i>
       <swiper :options="swiperOption2" class="horizontal-swiper" ref="mySwiper2">
           <swiper-slide class="horizontal-swiper-item" v-for="(item,index) in patent1" :key="index">
             <div class="patent-container">
               <div  v-for="itemm in item">
                  <img  :src="itemm" >
                  </div>
               </div>
           </swiper-slide>
           <div class="swiper-pagination2" slot="pagination"></div>
         </swiper>
      </div>
   </swiper-slide> -->
   <swiper-slide class='vertical-swiper-item' >
     <!-- <div class="bg" style="backgroundImage:url(static/jianjie.jpg)"></div> -->
     <div class="box">
       <div class="title"  >最新荣誉</div>
        <!-- <i class="i1" :class="{iWidth:index==3}"></i> -->
     <rongyu class="rongyu" id="rongyu" />
     </div>
    </swiper-slide>
    <swiper-slide class='vertical-swiper-item' >
      <!-- <div class="bg" style="backgroundImage:url(static/jianjie.jpg)"></div> -->
      <div class="box">
        <div class="title" >合作伙伴</div>
         <!-- <i class="i1" :class="{iWidth:index==4}"></i> -->
      <coo class="coo"  />
      </div>
     </swiper-slide>
   <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
  <!-- <rongyu class="bg rongyu" id="rongyu" /> -->
  <!-- <coo class="coo" id="coo" /> -->
  <!-- <contact class="contact"/> -->
  <foot/>
  </div>
</template>
<script>
    import foot from './footer'
    import about from './about/about'
    import rongyu from './patent'
    import api from '../api.js'
    import coo from './coonperate'
    //import 'swiper/dist/css/swiper.css'
    //import { swiper, swiperSlide } from 'vue-awesome-swiper'
  export default{
    components:{
      foot,
      about,
      rongyu,
    //  swiper,
    //  swiperSlide,
      coo
    },
    data(){
      return{
           index:0,
           clientWidth:document.body.clientWidth,
           clientHeight:document.body.clientHeight,
           // img1:[],
           // patent:[],
           // patent1:[],
           tab:[{name:'公司简介'},{name:'知识产权'},{name:'公司专利'},{name:'公司荣誉'}],
           swiperOption: {
                   direction:'vertical',
                   slidesPerView: 1,
                   spaceBetween: 0,

                   mousewheel: true,
                   // effect : 'fade',//切换模式
                   speed:500,//速度
                   pagination: {
                   el: '.swiper-pagination',
                   clickable: true,
                   renderBullet: function (index, className) {
                     console.log(className)
                   return '<span  class="' + className + ' bullet"></span>';
           },
         },
         on: {
             slideChangeTransitionEnd: function(){
               this.index=this.activeIndex
             }.bind(this),
           },
                 },
      //
                 swiperOption1: {
                         direction:'vertical',
                         slidesPerView: 1,
                         spaceBetween: 0,
                         autoplay: {
                          delay: 1000,//1秒切换一次
                         },
                         mousewheel: true,
                         // effect : 'fade',//切换模式
                         speed:500,//速度

                       },
           zhuozuo:['超融合实景指挥系统后台管理软件[简称：超融合实景指挥系统后台管理软件]V1.0','超融合实景指挥系统标签配置软件[简称：超融合实景指挥系统标签配置软件]V1.0','超融合实景指挥系统自动标签POI下载导入软件[简称：超融合实景指挥系统自动标签POI下载导入软件]V1.0','超融合实景指挥系统软件[简称：超融合实景指挥系统软件]V1.0','AR接入网关软件[简称：AR接入网关软件]V1.0','一机三屏调度软件[简称：一机三屏调度软件]V1.0','低点摄像机AR化应用服务软件[简称：低点摄像机AR化应用服务软件]V1.0.','场景回溯应用软件[简称：场景回溯应用软件]V1.0','媒体转发服务软件[简称：媒体转发服务软件]V1.0','平台级联管理服务软件[简称：平台级联管理服务软件]V1.0','应用管理服务软件[简称：应用管理服务软件]V1.0','数据管理服务软件[简称：数据管理服务软件]V1.0'],
           zhuanli:['一种视频标签定位方法','基于多层架构的视频人工智能训练平台','一种多维度数据的视频整合方法','一种基于场景的视频识别方式优化及推送方法','基于全息位置地图的视频联动调度方法','一种视频神经元节点的空间重构方法','一种实现摄像机系统的盲区协防系统（201920166997.6）','PTC国际专利：一种视频标签定位方法','在视频中直接操作机电设备的装置']
      }
    },
    mounted(){
      this.$refs.mySwiper.swiper.mousewheel.disable();
          // window.addEventListener('resize',this.windowResize)
          //       setTimeout(()=>{
          //         this.init()
          //       },500)
      //           var img=[]
      //           var patent=[]
      // for(var i=1;i<13;i++){
      //     var str=`./static/zizhi${i}.png`
      //     img.push(str)
      //      }
      //      this.img1.push(img.slice(0,3))
      //      this.img1.push(img.slice(3,6))
      //      this.img1.push(img.slice(6,9))
      //      this.img1.push(img.slice(9,12))
      // for(var i=0;i<6;i++){
      //    var str=`./static/patent${i}.png`
      //     patent.push(str)
      //
      //         }
      //   this.patent1.push(patent.slice(0,3))
      //   this.patent1.push(patent.slice(3,6))
    },
    computed:{
      scroll(){
        return this.$store.state.scrollTop
      },
      left(){
        return (this.clientWidth-1920)/2
      },
      activeIndex(){
        return this.$refs.mySwiper.swiper.activeIndex
      }
    },
    methods:{
      windowResize(){
                     // 通过捕获系统的onresize事件触发我们需要执行的事件
                     this.clientWidth = document.body.clientWidth
                     //console.log(this.clientWidth)

                 },

    },
    watch:{
      scroll(val){
        let height=document.getElementById('mySwiper1').offsetHeight
        console.log(val+this.clientHeight>=height+488)
        if(val+this.clientHeight>=height+488){

          this.$refs.mySwiper.swiper.mousewheel.enable();
        }
      },

    }
  }
</script>
<style lang="scss" scoped>
@import '../style/zizhi';
.pdflink,.pdflink:visited
{
  display: block;
  position: absolute;
  right: 50px;
  bottom: 10px;
  color: #00c4e2;
  font-weight: 300;
  line-height: 2.5;
  font-size: 36px;
  color: #00c4e2;
}
</style>
