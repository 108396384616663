<template>
  <div class="container">
    <div class="right-tab">
       <div class="tab"  v-for="(item,index) in tab" @click="turn(index)"><span>{{item}}</span></div>
      </div>
    <div class="head-warp" >
       <div class="back"></div>
       <div class="head-title">
          <div class="title">
            <div>{{content.name}}</div>
            <div>
              以客户为中心，
              以需求为导向，
              以服务为宗旨
            </div>
          <!-- <p class="detail">
            以客户为中心，
            以需求为导向，
            以服务为宗旨
          </p> -->
        </div>
       </div>
     </div>
     <div class="content-box">
         <div v-for="(item,index) in anlis" ref='anli' :class="item.alias+'box'">
            <div class="title title-background">{{item.name}}</div>
            <div class="box" :class="item.alias">
               <div class="content"  v-for="itemm in item.anli">
                  <div class="content-title">{{itemm.title}}</div>
                  <p>{{itemm.content}}</p>
                  <div class="img-box" >
                     <img v-for="itemmm in itemm.img" :src="itemmm" @click="zoom(itemmm)"/>
                  </div>
                </div>
            </div>
        </div>
      </div>
      <div class="bg-img" v-show="showImg">
        <div class="tra-img">
            <img :src="thisImg" class='zoom-out' @click="zoomOut">
        </div>
      </div>
      <foot/>
  </div>
</template>
<script>
  import foot from './footer'
  import api from '../api'
  export default{
    components:{
      foot,
    },
    data(){
      return{
        tab:['智慧公安','智慧交通','智慧旅游','智慧教育','智慧生产'],
         content:{name:"案例"},
         anlis:[{
           name:'智慧公安',
           alias:'gongan',
         anli:[{
           title:"麻阳县公安局",
           content:'视云为麻阳县公安局成功部署了视频空间大数据平台，深度融合了公安执法数据、公安视频数据、现场执法平台等，提供了基于视频的应急指挥调度功能、一机三屏联合展示功能、现场执法指挥功能等，为平安麻阳的建设提供关键的技术支撑。',
           img:[ 'static/mayang1.png',
                 'static/mayang2.png',
                 'static/mayang3.png',
                 'static/mayang4.png'],
         },{
           title:"女子戒毒所",
           content:'视云为戒毒所成功部署了视云视频空间大数据平台，系统利用增强现实智能标签算法引擎，通过标签联动画中画调用低点视频、卡口等资源，做到监控画面的整体与局部同时覆盖，真正做到由上到下、由内到外的立体化防控体系。实现实时和全面的安防监控，从被动监管转变为主动监管，为监狱安全防护顺利保驾护航。',
           img:['static/jiedu1.png',
                'static/jiedu2.png',
                'static/jiedu3.png',
                 ],
         },{
           title:"海珠分局",
           content:'视云为海珠公安分局成功部署了视云空间大数据平台，在系统中采用优秀的解码算法，实现单个CPU+1块1070显卡，可以同对20路视频进行实时解码。对于海珠分局现有设备的基础上，办案效率有了质的提升。',
           img:['static/haizhu1.png',
                'static/haizhu2.png',
                 'static/haizhu3.png'
                ]
         },{
           title:"南昌VR大会",
           content:'视云为南昌VR大会安保成功部署了视云空间大数据平台，通过无人机拍摄现场的全景图作为整个系统的高点视频，通过从高点连接低点，通过低点连接周边低点的模式，建立场景化业务模型，实施扁平化大会安保指挥。',
           img:['static/nanchang1.png',
                'static/nanchang2.png',
                 'static/nanchang3.png'
                ]
         },{
           title:"江西景德镇省运会",
           content:'视云为江西省运会成功部署了视云空间大数据平台，平台采用无人机拍摄运动会场全景图作为高点视频，提供周边视频查询、人员的跨境追踪、视频的盲区协防，为省运会的安保工作提供了重要的技术支撑。',
           img:['static/jiangxi1.png',
                'static/jiangxi2.png',
                 'static/jiangxi3.png'
                ]
         }]
       },{
         name:'智慧交通',
         alias:'jiaotong',
         anli:[{
           title:'新郑新区收费站',
           content:"新郑新区收费站作为全国第一个无人值守的收费站，通过部署视云视频大数据平台，实现对收费站现场的实时监控和应急指挥功能，同时，平台集成了车辆识别技术，车牌识别技术，人员识别技术等交通AI智能算法，为收费站的正常运营提供有力的保障。",
           img:['static/xinzeng1.png',
                'static/xinzeng2.png',
                 'static/xinzeng3.png'
                ]
         },{
           title:"禹登高速",
           content:'禹登高速采用视云的视频大数据平台，通过在视频实景地图中添加标签的方式对高速公路的资产进行精细化的管理。',
           img:['static/yudeng1.png',
                'static/yudeng2.png',
                 'static/yudeng3.png'
                ]
         },{
           title:"立体可视化智慧路网",
           content:'智慧路网立体可视化感知平台，可通过现有视频直接采集/展示高速公路的路网营运数据，主要解决了高速公路监控资源的使用效能低下，体验不好的问题，结合场景AI解决了高速公路“保安全、保畅通、保收费” 的营运管理问题。',
           img:['static/liti1.png',
                'static/liti2.png',
                 'static/liti3.png'
                ]
         },{
           title:'粤运码头',
           content:'粤运码头成功部署视云视频大数据平台，利用现有的摄像头，通过视频标签的方式对整个码头的出入口、主干道路、堆场、周边、仓库等重要场所进行实时智能化监控，对发生可疑或异常行为及时采取报警、记录，为整个码头的安全生产管理提供技术保障。',
           img:['static/matou1.png',
                'static/matou2.png',
                 'static/matou3.png'
                ]
         },{
           title:'驻马店高速公路',
           content:'驻马店高速公路成功部署视云视频大数据平台，通过无线4G实时传输图像，并对大量的业务数据进行了融合，对服务区进行了场景化的实时表现。',
           img:['static/zhumadian1.png',
                'static/zhumadian2.png',
                 'static/zhumadian3.png'
                ]
         }]
       },{
         name:"智慧旅游",
         alias:'lvyou',
         anli:[{
           title:'月牙泉动态交互景区管理',
           content:"月牙泉成功部署视云视频大数据平台旅游版，满足景区实景化指挥、可视化运营、智慧化管理的需求，通过与景区已建的监控系统、业务系统进行深度对接融合多枪拼接的全景监控，网格化的管理，安保人员的精细化管理，人工智能的分析，在视频实景上展现监控视频、票务信息、闸机信息、游览车位置、安保人员位置、各类运营数据、报警等数据，打造成了智能化景区管理系统",
           img:['static/yueyaquan1.png',
                'static/yueyaquan2.png',
                 'static/yueyaquan3.png'
                ]
         }]
       },{
         name:"智慧教育",
         alias:'jiaoyu',
         anli:[{
           title:'广州知信小学',
           content:'广州执信小学部署了视云视频大数据平台教育版平台，系统集校园视频监控、周界防范、非法入侵、应急预案演练、视频结构化、应急指挥、预警处置等校园安防业务于一体，结合人脸智能算法，将学生课堂考勤、班级信息等教务信息进行直观的展示应用，构成针对平安校园的智能化管理系统。',
           img:['static/zhixin1.png',
                'static/zhixin2.png',
                 'static/zhixin3.png'
                ]
         }]
       },{
         name:'智慧生产',
         alias:'shengchang',
         anli:[{
           title:'山里水工厂实景指挥',
           content:'佛山里水工厂部署了视云视频空间大数据平台工厂版，以视频监控和生产管理为核心，依托AR实景监控系统平台，整合工厂园区各类安防系统和生产数据，利用AR增强现实、物联网、视频智能分析技术，实现生产车间的烟感、设备温度等生产数据的AR实景展现、设备状态灯的智能检测，并能对生产调度和生产管理产生的数据进行统计分析，利用科技的手段提高企业管理水平和生产效率，保证工厂园区内人员、财产、物资的安全。',
           img:['static/shengchang1.png',
                'static/shengchang2.png',
                 'static/shengchang3.png'
                ]
         }]
       }],
         thisImg:'',
         showImg:false,
      }
    },
    mounted(){
         const index=this.$route.params.index||''
         if(index){
         setTimeout(()=>{
            api.goTo(this.$refs.anli[index].offsetTop-87)
         },200)
       }
       this.anlis.forEach(item=>{
         item.anli.forEach(itemm=>{
           itemm.img.pop()
           if(itemm.title=='麻阳县公安局'){
             itemm.img.pop()
           }
         })
       })

    },
    methods:{
      turn(index){
     api.goTo(this.$refs.anli[index].offsetTop-87)
      },
     zoom(item){
       console.log(item)
       this.thisImg=item
       this.showImg=true
     },
     zoomOut(){
       this.showImg=false
     }
    }
  }
</script>
<style lang="scss" scoped>
@import '../style/case';
</style>
