<template>
  <div class="coo-container">
    <!-- <div class="head-warp">
      <div class="back"></div>
      <div class="head-title">
         <p class="title">视云融聚合作伙伴</p>
         <p class="detail">
          致力于帮助合作伙伴构建基于视云融聚的业务实践和解决方案
         </p>
       </div>
    </div> -->
    <div class="content">
      <!-- <div class="title title-background">合作伙伴</div> -->
        <div class="img-container" >

          <div v-for="item in img.slice(0,3)">
            <div>
          <img  :src="item">
          </div>
          </div>
         </div>
          <div class="img-container" >
            <div v-for="item in img.slice(3,6)">
              <div>
              <img  :src="item">
            </div>
            </div>
            </div>
    </div>
    <!-- <foot/> -->
  </div>
</template>
<script>
  // import foot from './footer'
  export default{
    components:{
      // foot
    },
    data(){
      return{
       img:[],
      }
    },
    mounted(){
     for(var i=1;i<7;i++){
       var str=`./static/coo${i}.png`
       this.img.push(str)

     }
    },
    methods:{

    }
  }
</script>
<style lang="scss" scoped>
@import '../style/cooperate';
</style>
