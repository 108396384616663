<template>
  <div class="container" style="backgroundImage:url(static/xingkong3.png)">
    <div class="head-warp">
      <div class="back" >
         <img class="back1" :style="{'left':left+'px'}" src='static/05banner.jpg'>
         <!-- <img class="back2" :style="{'left':left+'px'}" src='/static/back4.png'> -->
      </div>
      <div class="head-title">
         <!-- <p class="title"><img src="/static/chenwen.png"></p> -->
         <p class="title">加入视云融聚</p>
         <p class="title">梦想从这里开始启航</p>
       </div>
    </div>
    <div class="join-us" >
        <div class="title">
          <h3>招贤纳士</h3>
          <i class="i1"></i>
        </div>

        <div class="content">
          <div class="box"  v-for="item in wanted">
            <div class="face">
            <div class="name">{{item.name}}</div>
            <div class="adress">{{item.address}} </div>
            <div class="wanted" v-html="item.wanted"> 
            </div>
            </div>
            <div class="duty" v-html="item.duty">
            </div>
            </div>
        </div>
      </div>
      <contact class="contact"/>
      <foot/>
  </div>
</template>
<script>
    import foot from '../footer'
    import contact from '../contact/contact'
  export default{
    components:{
      foot,
      contact
    },
    data(){
      return{
        wanted:[{
          name:'AI工程师',
          address:'工作地点：广州、北京',
          wanted:'<strong>任职要求：</strong><br/> 1)    本科以上学历，数学、统计、计算机、物理、人工智能相关专业；3年以上人工智能产品相关工作经验；<br> 2)    熟悉大数据、人工智能的理论概念和技术应用，熟悉热点领域和主要产品形态；<br> 3)    熟悉机器学习相关算法；<br> 4)    熟悉BAT、GOOGLE等云平台的AI能力；<br> 5)    具有良好的沟通表达能力、产品规划能力和学习思考能力，富有创新精神。',
       duty:` <strong>岗位职责：</strong><br>
1)    基于公司的产品和解决方案进行大数据AI的应用规划，包括数据采集、数据加工、机器学习、人工智能等;<br>
2)    密切关注行业相关方向的前沿理论和技术研究，并组织团队将相关成果快速学习消化，应用到公司的产品中；<br>
3)    跟踪市场动向和行业趋势，分析竞争对手/竞品特点，及时调整市场策略，创新产品；<br>
4)    持续跟进产品上线后的运行效果和用户反馈，提出和实施产品改进方案；<br>
5)    不断探索产品创新点，不断优化打磨产品。<br>`
},{
    name:`软件测试工程师`,
    address:`工作地点：广州、北京、杭州、西安`,
    wanted:`<strong>任职要求：</strong><br/>
1)    计算机、电子类专科以上学历，具有3年以上软件测试工作经验;<br/>
2)    掌握基本的软件测试理论，熟悉软件测试的基本方法、流程和规范;<br/>
3)    熟悉Mysql、Sql Server、Oracle等常见数据库，掌握数据库的基本知识及数据库的各类基本操作;<br/>
4)    有一定文档编写能力，能根据用户需求及相关开发文档，设计、编写测试计划、用例和用户手册;<br/>
5)    熟悉视频监控系统，了解TCP、UDP、RTSP、28181等协议，有视频监控平台、流媒体服务、28181协议测试经验优先.<br/>
`,
    duty:`<strong>岗位职责：</strong><br/>
1)    负责视频监控类软件的功能测试、性能测试，反馈缺陷，撰写测试报告;<br/>
2)    独立编写产品编写测试方案、测试用例，执行测试和用户手册;<br/>
3)    严格按照测试流程和测试计划进行项目的软件测试，严格按照规范提交和管理BUG，跟进系统测试问题;<br/>
4)    参与项目需求评审，产品设计评审，分析并指正需求设计中存在的不足，给出恰当的解决方案;<br/>
5.根据被测产品需求文档进行测试方案及用例设计。<br/>
`
},{
     name:"现场技术支持工程师",
     address:'工作地点：广州、北京、杭州、西安',
     wanted:`<strong>任职要求：</strong><br/>
1)    大专以上学历，1年以上安防行业工作经验；<br/>
2)    了解平安城市项目整体解决方案，如：治安监控、道路卡口监控、电子警察等;<br/>
3)    了解Windows Server、Linux操作系统的安装部署、常用服务搭建、常用命令的使用;<br/>
4)    了解Oracle、Mysql数据库的安装、配置，能使用常用SQL语句；<br/>
5)    了解Tomcat、Apache等Web服务器配置，了解双机热备、常用系统工具的使用；<br/>  
6)    了解交换机、路由器、GPON、光纤收发器等网络设备；<br/>
7)    有安防行业技术支持、软件平台实施、运维支持、系统测试经验优先；<br/>
8)    具备较强的组织、沟通和协调能力，执行力强；<br/>
9)    具备良好的团队合作、吃苦耐劳的精神，能接受出差。<br/>
`,
   duty:`<strong>岗位职责：</strong><br/>
1)    负责公司产品的现场实施、技术支持及项目管理工作，包括项目前期准备、现场设备安装、软件平台调试及验收交付；<br/>
2)    负责与项目业主、施工队、市场、研发等沟通，协调各方资源，保证项目的顺利实施和验收；<br/>
3)    负责收集、反馈产品实施中遇到的问题，提交产品使用情况、产品修改意见、用户需求;<br/>
4)    负责公司产品测试、演示、交流、培训；<br/>
5)    公司领导交待的其它工作。<br/>
`
},
         {
          name:"售前工程师/产品经理",
          address:' 工作地点：广州、北京、杭州、西安',
          wanted:`<strong>任职要求： </strong><br/>
1)    二年以上成功的安防（视频监控）或网络视讯产品市场推广工作经验；<br/>
2)    熟悉公安、交通、政府、智能楼宇等主流行业的安防业务需求及发展趋势，具备项目运作及市场策划能力；<br/>
3)    具备良好的文案组织能力、口头表达能力，具备良好的PPT演示、演讲能力<br/>
4)    掌握视音频技术及解决方案，熟悉主流产品和应用者优先；<br/>
5)    勤奋、敬业，具备良好的学习能力与合作精神，服从工作安排、能适应出差；<br/>
6)    具有计算机、通信工程、电子相关专业本科或以上学历优先；<br/> `,
           duty:`<strong>岗位职责：  </strong><br/>
一、项目售前支持工作： <br/>
1)    负责公司安防产品的市场项目售前技术支持工作；<br/>
2)    2、负责项目售前的方案宣介、需求调研、方案引导、概算编写，立项申请、招投标等全流程的售前技术支持工作；<br/>
3)    负责渠道维护工作，包括产品宣介、日常技术培训、项目支撑的工作。 <br/>
二、产品经营推广工作： <br/>
1)    负责产品规划工作，包括需求分析、需求评审、需求跟进；负责产品方案编写，产品包制作、行业应用孵化、行业推广等工作;<br/>
2)    负责品牌推广工作，包括组织展会交流、技术沙龙、样板点参观等工作；负责公司产品的网络投稿、自媒体运营等推广工作；<br/>
3)    负责市场竞争分析并输出竞品报告。<br/>
`
},
        {
          name:"大数据开发工程师",
          address:'工作地点：广州、北京 ',
          wanted:`<strong>任职要求： </strong><br/>
1)    3年以上大数据平台产品或分布式系统设计经验;<br/>
2)    精通Hadoop、Hive、Hbase原理，熟悉大数据生态技术，如Storm、Spark、Flume、Kafka、Zookeeper等，有大规模集群架构、开发及运维经验；<br/>
3)    具备源码级问题解决和集群优化改造能力者优先;<br/>
4)    具备良好的团队合作精神，较强的沟通能力，具备良好的技术领导能力；<br/>
5)    具备良好的文档撰写能力和技术团队管理能力。 <br/>`,
         duty:`<strong>岗位职责： </strong><br/>
1)    参与安全大数据产品的架构的规划和设计;<br/>
2)    指导开发大数据平台的开发，保证大数据产品的上线运行；<br/>
3)    根据业务需求整合优化数据架构，保证产品的可靠性、稳定性;<br/>
4)    关注开源技术动态，推动平台技术架构持续更新;<br/>
`
},
{
    name:"WPF工程师",
    address:'工作地点：广州、北京、杭州、西安',
    wanted:`<strong>任职要求：</strong><br/>
1)    三年以上 WPF 开发经验；精通C#语言，掌握C#委托，事件，反射，多线程、Linq，异步编程开发，缓存机制;<br/>
2)    熟悉TCP/IP协议，熟悉SOCKET和HTTP网络编程；<br/>
3)    掌握WPF控件封装、数据绑定、命令、模板、样式和动画、触发器，样式，行为等技术；<br/>
4)    掌握WPF中的依赖项属性、附加属性、路由事件、MVVM框架等;<br/>
5)    熟悉多线程编程，熟悉异步操作、跨线程操作UI控件等;<br/>
6)    了解Entity Framework等ORM框架;<br/>
7)    能独立进行程序结构优化和性能优化;<br/>
8)    熟练掌握MySQL, SQLServer, Oracle等数据库编程操作;<br/>
9)    在并发、分布式、性能调优和大数据处理方面有经验的优先;<br/>
10)    有视频或安防监控行业工作经验者可优先考虑;<br/>
`,
   duty:`<strong>岗位职责：</strong><br/>
1)    进行系统的功能定义,程序设计；<br/>
2)    完成软件系统代码的实现，编写代码注释和开发文档；<br/>
3)    能够对系统稳定性和性能提出自己的见解和方案；配合项目完成相关任务目标。<br/>
`
},{
     name:'区块链研发工程师',
     address:'工作地点：广州、北京',
     wanted:`<strong>任职要求: </strong><br/>
1)    本科及以上学历，计算机等相关专业毕业；<br/> 
2)    熟悉区块链开源项目Hyperledger fabric的实现机制及运行原理，有区块链项目的开发/实施经验；<br/> 
3)    对密码学，安全协议和加密算法有研究，熟悉分布式系统架构和分布式存储相关原理；<br/> 
4)    精通Go或者Python语言，有Chaincode开发经验的优先；<br/> 
5)    了解HTTPS协议，理解gRPC框架，具备使用protobuf的开发实践经验；<br/>
6)    了解一些主流NoSQL数据库的原理与使用，包括不限于LevelDB，RocksDB等；<br/>
7)    理解各类主流的共识算法，包括不限于PoW，PoS，DPoS，PBFT，Paxos，Raft等；<br/>
8)    熟悉java、nodejs的优先；<br/>
9)    熟悉linux操作系统，熟练掌握Docker容器技术原理、部署和使用优化；<br/>
10)    有参与开源代码或者开源框架贡献者优先。<br/>`,
      duty:`<strong>岗位职责: </strong><br/>
1)    负责根据实际需求及应用场景，设计区块链运行机制，开发智能合约，承担技术层的业务规则Chaincode开发;<br/>
2)    负责研究Hyperledger fabric的加密技术、共识算法和底层实现;<br/>
`
},{
     name:'JAVA工程师',
     address:'工作地点：广州、北京、杭州、西安',
     wanted:`<strong>任职要求：</strong><br/> 
1)    3年以上的工作经验，拥有分布式架构、开发优化集成 经验；（应届毕业生不作年限要求）<br/>  
2)    具备扎实的java知识体系，熟悉Java EE,Redis、消息队列，对各种开源的框架如Spring、SpringMVC、MyBatis等有深入的了解，对JAVA通用框架本身有过开发或重构者可优先考虑;<br/> 
3)    具备丰富的Mysql数据库Query开发经验，对SlowQuery高度敏感者优先，或有NOSQL开发使用经验优先；<br/> 
4)    使用过数据库中间件（mycat,MHA等）优先考虑;<br/> 
5)    热爱技术研发、具有快速学习能力；注重代码质量、有良好的软件工程知识和编码规范意识;<br/>  
6)    有主导或者参与项目设计研发相关经验、有高并发、大数据处理实践经验者优。<br/>
`,
    duty:`<strong>岗位职责：</strong><br/>
1)    在理解项目需求的基础上，进行项目开发与系统优化;<br/>
2)    具备设计能力，参与产品或项目研发相关文档编写。<br/>
`
}],
clientWidth:document.body.clientWidth,
      }
    },
    mounted(){
      // window.onresize = function windowResize () {
      //                // 通过捕获系统的onresize事件触发我们需要执行的事件
      //                this.clientWidth = document.body.clientWidth
      //                // console.log(this.clientWidth)
      //
      //            }.bind(this)
       window.addEventListener('resize',this.windowResize)
    },
    computed:{
      left(){
        //if(this.clientWidth>1400){
        return (this.clientWidth-1920)/2
    //  }
      }
    },
    methods:{
      windowResize(){
                     // 通过捕获系统的onresize事件触发我们需要执行的事件
                     this.clientWidth = document.body.clientWidth
                     //console.log(this.clientWidth)

                 }
    }
  }
</script>
<style lang="scss" scoped>
@import './join';
</style>
