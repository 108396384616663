<template>
  <div class="container" :class="fix?'fix':'absolute'">
    <div class="logo" @click="$router.push({path:'/'})">
      <img src="/static/logo2.png">
    </div>
    <div class="nav">
      <div class="chao li">
        <div @click="$router.push({path:'/'});">
          <span class="top" style="color:rgb(0,84,255)"> 首页</span>
          <span  class="top" >首页</span>
        </div>
      </div>

        <div class="chao li">
          <div >
            <!-- <span class="top" style="color:#fbea00"> 超融合</span>
            <span  class="top" > 超融合</span> -->
            <!-- <el-dropdown placement='bottom'> -->
            <!-- <span class="title">产品
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span> -->
            <!-- <el-dropdown-menu slot="dropdown" class="menu">
            <el-dropdown-item v-for="item in routerr" :key="item.name" @click.native="go(item)" class="cvos">{{item.name}}</el-dropdown-item>
            </el-dropdown-menu> -->
            <!-- </el-dropdown> -->
            <div class="ai li">
              <div @click="$router.push({name:'newProduct'});">
                <span class="top" style="color:rgb(0,84,255)">产品</span>
                <span class="top">产品</span>
              </div>
              </div>
          </div>
        </div>
        <div class="ai li">
          <div @click="$router.push({name:'AI'});">
            <span class="top" style="color:rgb(0,84,255)">场景AI</span>
            <span class="top">场景AI</span>
          </div>
          </div>
        <div class="anli li">
          <div @click="$router.push({name:'newCase'})">
           <span class="top" style="color:rgb(0,84,255)">案例</span>
           <span class="top">案例</span>
          </div>
         </div>
         <div class="about li">
           <div @click="$router.push({name:'zizhi'});">
               <!-- <el-dropdown placement='bottom'>
              <span class="title">关于视云<i class="el-icon-arrow-down el-icon--right"></i></span>
                <el-dropdown-menu slot="dropdown" class="menu">
              <el-dropdown-item @click.native="$router.push({name:'about'});" class="cvos">公司简介</el-dropdown-item>
              <el-dropdown-item @click.native="$router.push({name:'zizhi'});" class="cvos">公司资质</el-dropdown-item>
              <el-dropdown-item @click.native="$router.push({name:'patent'});" class="cvos">公司荣誉</el-dropdown-item>
              <el-dropdown-item @click.native="$router.push({name:'coonperate'});" class="cvos">合作伙伴</el-dropdown-item>
              <el-dropdown-item @click.native="$router.push({name:'contact'});" class="cvos">联系我们</el-dropdown-item>
            </el-dropdown-menu>
             </el-dropdown> -->
             <span class="top" style="color:rgb(0,84,255)">关于视云</span>
             <span class="top">关于视云</span>
             </div>
           </div>
        <div class="jiaru li">
          <div @click="$router.push({name:'join'})">
            <span class="top" style="color:rgb(0,84,255)">加入我们</span>
            <span class="top">加入我们</span>
            </div>
          </div>
      </div>
  </div>
</template>
<script>
  export default{
    data(){
      return{
          routerr:[]
      }
    },
    mounted(){
      this.routerr=this.$store.state.product.concat(this.$store.state.product1)
    },
    computed:{
      fix(){
        return this.$store.state.fix
      }
    },
    methods:{
    go(item){
      const content = JSON.stringify(item)
      this.$router.push({path:`${item.router}/${item.name}`,query:{content:content}});
    }
    }
  }
</script>
<style lang="scss" scoped>
@import '../style/hader';
</style>
