<template>
  <div class="container">
    <!-- <div class="head-warp">
      <div class="back"></div>
      <div class="head-title">
         <p class="title">视云融聚是中国人工智能的</p>
         <p class="detail">
          <img src="/static/dengta3.png">
         </p>
       </div>
    </div> -->
    <div class="content">
      <h3>联系我们</h3>
      <i class="i1" ></i>
       <div class="way">
         <div class="flex-box">
           <div class="title">
             广州总部
             <i class="i1"></i>
           </div>
           <div class="item">
           <span class="name">公司地址：</span>广州市黄埔区南翔二路1号旗锐科技园A座二层
           </div>
         <div class="item"><span class="name">合作邮箱：</span><i class="iconfont icon-youxiang icon"></i><span>info@cloudvos.com</span></div>
         <div class="item"><span class="name">传真：</span><i class="iconfont icon-chuanzhen icon b"></i><span>020-82557895</span></div>
        <div class="item"><span class="name">业务热线：</span><i class="iconfont icon-dianhuazixunhuawuzongheguanlibl icon a"></i><span>020-82003023</span></div>

        </div>
        </div>
        <div class="map">
          <div class="map-container">
            <div id="map" @click='openMap'></div>
          </div>
        </div>
        <div class='banshichu'>
         <h3>办事处</h3>
         <i class="i1" ></i>
         <div class="items">
         <div class="item" v-for="item in banshichu">
            <div class="item-title">{{item.title}}：</div>
            <div class="add">{{item.add}}</div>
            <div class="phone">电话：{{item.phone}}</div>
          </div>
       </div>
       </div>
    </div>
    <!-- <foot/> -->
  </div>
</template>
<script>
  // import foot from './footer'
  export default{
    components:{
      // foot
    },
    data(){
      return{
        banshichu:[{
          title:"北京办事处",
          add:'海淀区北三环西路48号北京科技会展中心A座甲12楼B',
          phone:'18611633012'
        },{
          title:"天津办事处",
          add:'天津河西区佟楼新闻西里20-101-104，105-108',
          phone:'18918001990'
        },{
          title:"西安办事处",
          add:'陕西省西安市雁塔区电子城街道沣惠南路泰华金贸国际6号楼1904',
          phone:'029-88604186'
        },{
          title:"杭州办事处",
          add:'杭州滨江区共联路519号11楼1102室',
          phone:'19805815460'
        },{
          title:"湖南办事处",
          add:'长沙市开福区芙蓉中路一段88号4栋1504室',
          phone:'0731-85517112'
        }]
      }
    },
    mounted(){
        this.init()

    },
    methods:{
      init(){
        this.map = new AMap.Map('map', {
          // resizeEnable: true,
        zoom:40,//级别
        pitch:25,
        center: [113.43871,23.156107],//中心点坐标
      //  viewMode:'3D'//使用3D视图
    });
    var marker = new AMap.Marker({
      position:[113.43923,23.155856]//位置
  })
  this.map.add(marker);//添加到地图
  },
   openMap(){
     window.open("https://ditu.amap.com/regeo?lng=113.43923&lat=23.155856&src=uriapi&name=视云融聚",'_blank');
   },
    }
  }
</script>
<style lang="scss" scoped>
@import './contact';
</style>
