<template>
  <div class="container" >
    <!-- <div class="bg" style1="backgroundImage:url(static/jianjie.jpg)"></div> -->
    <!-- <div class="content-box">
    <div class="content">
    <div class="box">
        <div class="box-left">
            <div>
               <h2>
                 <strong>视云融聚</strong>

               </h2>
               <div class="linian"><strong>【经营理念】</strong>：创新、融合、责任          品牌、开拓、生态</div>
               <div class="linian"><strong>【服务宗旨】</strong>：以服务伙伴为中心，以场景落地为导向</div>
               <p>
视云融聚（广州）科技有限公司（简称：视云融聚）成立于2017年，是一家云视频应用领域的高科技创新企业，探索和开发云视频应用领域各项前沿技术，公司围绕CLOUDVOS（cloud video os）云视频操作系统，研究大数据与行业应用、AI与业务场景结合的落地解决方案，拥有的主要技术包括AR增强现实、人工智能、云计算、大数据、数据可视化等。公司成立之初，以其创新的技术理念获得了东方网力等多个资方的战略投资，目前，公司与东方网力、阿里云等知名企业达成战略合作伙伴关系，在产品和商业上深度融合，面向公安、交通、教育、旅游等多个行业提供优秀的解决方案和服务，以视频作为信息和服务的入口，梳理、改善、深化行业业务应用，通过视频即应用，视频即服务，再造用户价值。
               </p>
               <p>
                 目前在天津、西安、杭州有三个研发分中心。并设立了北京、湖南、天津、杭州、西安、兰州等多地办事处
                </p>
            </div>
          </div>
       <div class="box-right">
             <img src="/static/quanguo1.png">
         </div>
      </div>
      </div>
      </div> -->
<div class="content">
   <h3 >视云融聚</h3>
   <i class="i1" :class="{iWidth:index==swiperIndex}"></i>
   <div class="detail" :class="{animation:index==swiperIndex}">
     公司拥有AR增强现实、人工智能、云计算、大数据、数据可视化等先进技术，面向公安、交通、教育、旅游等多个行业提供落地的解决方案和服务，以视频作为信息和服务的入口，梳理、改善、深化行业业务应用，通过视频即应用，视频即服务，再造用户价值。
    </div>
  
  </div>

  </div>
</template>
<script>
    // import foot from './footer'
  export default{
    props:['index','swiperIndex'],
    components:{
      // foot
    },
    data(){
      return{

      }
    },
    mounted(){


    },
    methods:{

    }
  }
</script>
<style lang="scss" scoped>
@import './about';
</style>
